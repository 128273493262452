
import api from '@/api/co.api'

const Names = {
  COLLECTION: 'collection',
  UNDERSTAND: 'understand'
}

const CatCollection = {
  title: '政策集锦',
  name: Names.COLLECTION,
  href: '/policy/list',
  topicId: '',
  categoryId: '',
  type: api.comm.NoticeTypes.POLICYS,
  navigated: false,
  indicator: 'mdi-file-document-outline',
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 1,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatUnderstand = {
  title: '政策解读',
  name: Names.UNDERSTAND,
  href: '/policy/list',
  topicId: '',
  categoryId: '',
  type: api.comm.NoticeTypes.EXPLAINS,
  navigated: false,
  indicator: 'mdi-file-image-outline',
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 1,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const policyPreset = {
  DetailName: api.page.policyDetail.name,
  ListName: api.page.policyList.name,
  Names,
  Parts: {
    [Names.COLLECTION]: CatCollection,
    [Names.UNDERSTAND]: CatUnderstand
  }
}

export default policyPreset
