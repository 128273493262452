<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="notice_detail"
      space="40"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex flex-column"
            >
              <land-notice-header :info="notInfo" />
              <land-notice-content :info="notInfo" />
              <land-notice-accessory :info="notInfo" />
              <land-notice-previous-next
                :previous="notPrev"
                :next="notNext"
                :part="notPart"
                @click="onNotice"
              />
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="hidden-sm-and-down"
          >
            <div
              v-for="(part, partIndex) in sideParts"
              :key="`part_${partIndex}`"
              class="d-flex flex-column ml-8"
            >
              <land-navigation
                :title="part.title"
                :items="part.buttons"
                size="text-lg-h6"
                :margin="`${partIndex > 0 ? 'mb-2 mt-6' : 'mb-2'}`"
                @click="toNotices($event, part)"
              />
              <land-plain-notices
                :items="part.items"
                short-dated
                back-color="white"
                card-color="grey lighten-4"
                padding="py-2"
                :indicator="part.indicator"
                :indicator-small="false"
                @click="changeNotice($event, part)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import lib from '@/api/co.lib'

  import mixPolicyBanner from '@/pages/mixins/policy/mix.policy.banner'
  import mixPolicyDetail from '@/pages/mixins/policy/mix.policy.detail'

  import mixPreset from '@/pages/mixins/policy/mix.policy.preset'

  export default {
    metaInfo: { title: '创新政策' },
    mixins: [
      mixPolicyBanner,
      mixPolicyDetail
    ],
    data () {
      return {}
    },
    created () {
      const name = mixPreset.DetailName
      const nav = JSON.parse(lib.cookie.get(name))
      Object.assign(this.navMeta, nav)

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      const part = mixPreset.Parts[nav.name] || {}
      Object.assign(this.notPart, part)

      this.setCrumbs(part, false)
      this.configSide([nav.name])

      this.getNotice({
        noticeId: nav.noticeId
      })
      this.getSibing({
        noticeId: nav.noticeId
      })
    },
    methods: {
      changeNotice (ev, part) {
        this.toNotice(part, ev.item)
      }
    }
  }
</script>
