
import api from '@/api/co.api'
import lib from '@/api/co.lib'

import activity from '@/api/co.activity'
import survey from '@/api/co.survey.js'

const toProblem = function ({
  that,
  pagename = '',
  item = {},
  category = null
}) {
  const params = {
    questionnaireId: item.questionnaireId,
    type: item.type,
    title: item.title
  }

  const name = pagename || api.page.homeProblem.name
  lib.cookie.set(name, JSON.stringify(params))
  that.$router.push({ name })
}

const toActivity = function ({
  that,
  pagename = '',
  item = {},
  category = null
}) {
  const params = {
    activityId: item.activityId,
    type: item.type,
    title: item.title
  }
  const name = pagename || api.page.activityDetail.name
  lib.cookie.set(name, JSON.stringify(params))
  that.$router.push({ name })
}

const toNotice = function ({
  that,
  pagename = '',
  item = {},
  category = null
}) {
  const params = {
    name: category.name,
    noticeId: item.noticeId,
    type: item.type,
    title: item.title,
    topicId: item.topicId,
    categoryId: item.categoryId
  }

  const name = pagename
  lib.cookie.set(name, JSON.stringify(params))
  that.$router.push({ name })
}

const toNotices = function ({
  that,
  pagename = '',
  item = {},
  category = null
}) {
  if (!category) {
    return
  }

  const params = {
    name: category.name,
    title: category.title,
    type: category.type,
    topicId: category.topicId,
    categoryId: category.categoryId,
    indicator: category.indicator
  }
  const name = pagename
  lib.cookie.set(name, JSON.stringify(params))
  that.$router.push({ name })
}

const Actions = {
  [survey.comm.Types.QUESTIONNAIRE]: toProblem,
  [survey.comm.Types.VOTE]: toProblem,
  [activity.comm.Types.ACTIVITY_REGISTER]: toActivity,
  [activity.comm.Types.PROJECT_REPORT]: toActivity,
  notice: toNotice,
  notices: toNotices
}

const NotTypes = api.comm.NoticeTypes
const NoticeTypes = [
  NotTypes.BANNER,
  NotTypes.NEWS,
  NotTypes.INFO,
  NotTypes.POLICYS,
  NotTypes.EXPLAINS,
  NotTypes.PUBLICS,
  NotTypes.PARKS,
  NotTypes.PROJECTS
]

const coAction = {
  navigate ({
    that,
    pagename = '',
    item = {},
    category = null
  }) {
    let type = item.type || ''
    if (NoticeTypes.indexOf(type) !== -1) {
      type = 'notice'
    }
    const action = Actions[type] || null
    if (typeof action === 'function') {
      action({ that, pagename, item, category })
    }
  }
}

export default coAction
